<template>
  <form @submit.stop.prevent="submit">
    <loading-overlay
      :active="apiStateLoading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="$t('label.edit')+' '+$t('label.damageType')+': '+TpDamageNameEn"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(ES)'"
            :placeholder="$t('label.damageTypeName')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            v-model="$v.TpDamageName.$model"
            :is-valid="hasError($v.TpDamageName)"
            :invalid-feedback="errorMessage($v.TpDamageName)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')+' '+'(EN)'"
            :placeholder="$t('label.damageTypeName')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            v-model="$v.TpDamageNameEn.$model"
            :is-valid="hasError($v.TpDamageNameEn)"
            :invalid-feedback="errorMessage($v.TpDamageNameEn)"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="11" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.acronym')"
            :placeholder="$t('label.damageTypeAcronym')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            v-model="$v.TpDamageAbbrev.$model"
            :is-valid="hasError($v.TpDamageAbbrev)"
            :invalid-feedback="errorMessage($v.TpDamageAbbrev)"
            maxlength="5"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="11" lg="12">
          <CSelect
            :options="statusOptions"
            :value.sync="Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="statusConfirmation(tpDanoObj.FgActTpDamage, Status, submit, tpDanoObj.TpDamageName, tpDanoObj.TpDamageNameEn)"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{$t('button.accept')}}</span>
          </div>
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="toggle(false)"
          :disabled="isSubmit"
        >
          <CIcon name="x" />
          <span class="ml-1">
            {{$t('button.cancel')}}
          </span>
        </CButton>
      </template>
    </CModalExtended>
  </form>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import EirTipoDanoFormValidations from '@/_validations/eir/tpdano/EirTipoDanoFormValidations';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    TpDamageNameEn: '',
    TpDamageName: '',
    TpDamageAbbrev: '',
    Status: 0,
    TpDamageId: '',
    apiStateLoading: false,
    tpDanoObj: {},
  };
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();
  else
    this.$v.$touch();
  this.modalActive = newVal;
}

async function submit() {
  try {
    this.$v.$touch();
    this.apiStateLoading = true;
    if (this.$v.$error) {
      this.apiStateLoading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    const TpDamageJson = {
      TpDamageId: this.TpDamageId,
      TpDamageName: this.TpDamageName,
      TpDamageNameEn: this.TpDamageNameEn,
      TpDamageAbbrev: this.TpDamageAbbrev,
      Status: this.Status,
      //  Status: this.Status == 'ACTIVO' ? 1 : 0,
    };

    await this.$http
      .put('TpDamage-update', TpDamageJson, { root: 'TpDamageJson' })
      .then((response) => {
        if (response && response.status === 200) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          const TpDanoJson = data;
          this.resetForm();
          this.$emit('set-type-damage-list', TpDanoJson);
          this.apiStateLoading = false;
          this.toggle(false);
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.apiStateLoading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.apiStateLoading = false;
    this.notifyError({text: e});
  }
}

function statusSelectColor() {
  return this.Status === 1;
}

function resetForm() {
  this.TpDamageName = '';
  this.TpDamageNameEn = '';
  this.TpDamageAbbrev = '';
  this.Status = 0;
  this.$v.$reset();
}

function mountInputsValues(item) {
  
  this.tpDanoObj = item;
  this.TpDamageName = this.tpDanoObj.TpDamageName;
  this.TpDamageNameEn = this.tpDanoObj.TpDamageNameEn;
  this.TpDamageAbbrev = this.tpDanoObj.TpDamageAbbrev;
  this.TpDamageId = this.tpDanoObj.TpDamageId;
  this.Status = this.tpDanoObj.FgActTpDamage?1:0;
}

export default {
  name: 'modal-edit-type-damage',
  props: { modal: Boolean, typeDamageSelect: Object },
  data,
  mixins: [
    GeneralMixin, 
    ModalMixin
  ],
  directives: UpperCase,
  validations: EirTipoDanoFormValidations,
  methods: {
    toggle,
    resetForm,
    statusSelectColor,
    mountInputsValues,
    submit,
  },
  watch: {
    typeDamageSelect: function(val) {
      this.mountInputsValues(val);
    },
  },
  
};
</script>
